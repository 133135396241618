import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import './formDirEnvio.scss'
import { useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import InputPaises from './InputPaises'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../shared/components/Spinner'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Alert } from '@mui/material'
import DeliverySchedule from './DeliverySchedule'
import GooglePlacesAutocomplete from '../shared/components/GooglePlacesAutocomplete'
import { isAddressOutOfRange } from '../../../../../../../shared/helpers/functionalities'
import { checkIntracommunityNIF } from '../../../../../../../../shared/services/checkout'

const FormDirEnvio = ({ className = '', dirEnvio, addNewDirection, uploadDirection = ()=>{}, isDirFact = false, formEnvioRef, isOpenDirFacturacion, handleSelectDirFact }) => {
  const { t } = useTranslation()
  const checkboxRef = useRef()
  const [currentPaisId, setCurrentPaisId] = useState(1)

  const [addressIssue, setAddressIssue] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [showNifNoIntracomunitario, setShowNifNoIntracomunitario] = useState(false)
  
  const checkoutInfo = useSelector(state => {
    return state.checkout.entity
  });

  const { 
    register, 
    formState: { errors }, 
    handleSubmit, 
    setValue, 
    getValues,
    watch,
    setError,
    clearErrors,
    reset } = useForm({
        mode: "onBlur",
        defaultValues: {
            id: '',
            nombre: '',
            telefono: '',
            direccion: '',
            codigo_postal: '',
            poblacion: '',
            area: '',
            pais_id: 1,
            horario_entrega_dia: '',
            horario_entrega_tarde: '',
        }
  });
  
  useEffect(() => {
    if (dirEnvio === 'otra') {
      reset({
        id: '',
        nombre: '',
        telefono: '',
        direccion: '',
        codigo_postal: '',
        poblacion: '',
        area: '',
        pais_id: 1,
    });
    } else {
      reset(dirEnvio);
      setCurrentPaisId(dirEnvio?.pais_id)
      
      !isDirFact && setValue('id', dirEnvio?.id)
      !isDirFact && setValue('horario_entrega_dia', dirEnvio?.horario_entrega_dia)
      !isDirFact && setValue('horario_entrega_tarde', dirEnvio?.horario_entrega_tarde)
      isDirFact && setValue('tipo_cliente_id', dirEnvio?.tipo_cliente_id)
      isDirFact && setValue('email', dirEnvio?.email)
      isDirFact && setValue('cif', dirEnvio?.cif)
      isDirFact && setValue('cif_ue', dirEnvio?.cif_ue)
    }
  }, [dirEnvio])

  useEffect(() => {
    if(addressIssue) {
      toast.error(t(`errors.${addressIssue.issue}`))
    }
  }, [addressIssue])
  
  const onSaveData = async (data) => {
    if (isLoading) return
    if (!isDirFact && isOpenDirFacturacion) {
      handleSelectDirFact()
      return toast.info(t('carrito.envio.form.errors.dir-fact'))
    }
    if(data?.cif) {
      const isValidCif = checkIsValidCif(data.cif);
      if(!isValidCif) return;
    }
    if(!checkIfNameIsNotEmail(data.nombre)) return;
    setIsLoading(true)
    // update invoicing address
    if (isDirFact) {
      data['direccion_facturacion'] = data['direccion']
      delete data['direccion'] 
      data['codigo_postal_facturacion'] = data['codigo_postal']
      delete data['codigo_postal']
      data['poblacion_facturacion'] = data['poblacion']
      delete data['poblacion']
      data['area_facturacion'] = data['area']
      delete data['area']
      data['pais_facturacion_id'] = data['pais_id'] ? data['pais_id'] : currentPaisId
      delete data['pais_id']

      // check nif ue si no lo ha clicado lo pasamos a false para que no vuelva a salir
      if(data.pais_id !== 1 && data.cif_ue === null) {
        data.cif_ue = false
      }
      
      return uploadDirection(data)
    }

    normalizeScheduleKeys(data)
    // update delivery schedule
    if (dirEnvio.id) {
      return uploadDirection(data)
    }
    
    // check different country
    if (!checkoutInfo.presupuesto.ecommerce) {
      if(checkoutInfo.presupuesto.direccion_envio_id !== null && checkoutInfo.direcciones.length > 0){
        const direccion = checkoutInfo.direcciones.find(dir => dir.id === checkoutInfo.presupuesto.direccion_envio_id)
        const { pais_id } = direccion
        if (pais_id !== data.pais_id) {
          setIsLoading(false)
          return setAddressIssue({status: true, issue: 'diferente'})}
      }
    }
    // check outside peninsula
    if(checkoutInfo.presupuesto.ecommerce && (
        (data.pais_id !== 1 && data.pais_id !== 10) || 
        (isAddressOutOfRange(data.codigo_postal, parseInt(data.pais_id)))
      )) { 
      setIsLoading(false)
      return setAddressIssue({status: true, issue: 'fuera-rango'});
    }

    // create shipping address
    await addNewDirection({...data, entity_id: checkoutInfo.cliente.id})
    setIsLoading(false) 
  }
  
  const handleChangePais = (e) => {
    setCurrentPaisId(e.target.value)
    setValue('pais_id', e.target.value)
  }

  const handleIntracomunitario = async (e) => {
    if(!getValues('cif')) return
    if(!checkboxRef.current.checked) setShowNifNoIntracomunitario(false)
    setIsLoading(true)
    if(checkboxRef.current.checked) {
      const country = checkoutInfo.paises.find(country => country.id === currentPaisId)
      const nif = getValues('cif')
      const res = await checkIntracommunityNIF({country: country.abbr, nif })
      if(res?.success) { 
        setValue('cif_ue', res.data.isValid)
        if(!res.data.isValid) {
          checkboxRef.current.checked = false
          setShowNifNoIntracomunitario(true)
        } else {
          toast.success(t('carrito.envio.form.info_cif_ue.ok'))
          setShowNifNoIntracomunitario(false)
        }
      } else {
        checkboxRef.current.checked = false
        toast.error(t('carrito.envio.form.info_cif_ue.error-request'))
      }
    }
    return setIsLoading(false)
  }

  const normalizeScheduleKeys = (data) => {
    
    //2 digits numbers
    const pad = (num) => num.toString().padStart(2, '0');
    
    if(data.horario_inicio_dia && data.horario_fin_dia) {
      data.horario_entrega_dia = `${pad(data.horario_inicio_dia)}:00-${pad(data.horario_fin_dia)}:00`;
    } else if (data.horario_entrega_dia && data.horario_inicio_dia) {
      data.horario_entrega_dia = `${pad(data.horario_inicio_dia)}:00-${data.horario_entrega_dia.split('-')[1]}`;
    } else if (data.horario_entrega_dia && data.horario_fin_dia) {
      data.horario_entrega_dia = `${data.horario_entrega_dia.split('-')[0]}-${pad(data.horario_fin_dia)}:00`;
    } else if (data.horario_inicio_dia === '' && data.horario_fin_dia === '') {
      data.horario_entrega_dia = null
    }
    
    if(data.horario_inicio_tarde && data.horario_fin_tarde) {
      data.horario_entrega_tarde = `${pad(data.horario_inicio_tarde)}:00-${pad(data.horario_fin_tarde)}:00`;
    } else if (data.horario_entrega_tarde && data.horario_inicio_tarde) {
      data.horario_entrega_tarde = `${pad(data.horario_inicio_tarde)}:00-${data.horario_entrega_tarde.split('-')[1]}`
    } else if (data.horario_entrega_tarde && data.horario_fin_tarde) {
      data.horario_entrega_tarde = `${data.horario_entrega_tarde.split('-')[0]}-${pad(data.horario_fin_tarde)}:00`;
    } else if (data.horario_inicio_tarde === '' && data.horario_fin_tarde === '') {
      data.horario_entrega_tarde = null
    }

    delete data.horario_inicio_dia;
    delete data.horario_fin_dia; 
    delete data.horario_inicio_tarde;
    delete data.horario_fin_tarde;
  }

  const handleChangeDireccion = (direccion) => {
    Object.keys(direccion).forEach(function(key) {
        setValue(key, direccion[key])
        if (direccion[key]) clearErrors(key);
    });
  }
  
  const isDisabledInput = (inputName) => {
    return (isDirFact || (dirEnvio !== 'otra' && checkoutInfo.direcciones.length > 0)) && dirEnvio[inputName] 
  }

  const checkIsValidCif = (value) => {
    if(value.length < 9) {
      setError('cif', {type: 'manual', message: 'error'})
      return false};
    if(/0{8}/.test(value)) {
      setError('cif', {type: 'manual', message: 'error'})
      return false;
    } 
    clearErrors('cif')
    return true;
  }

  const checkIfNameIsNotEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isEmail = regex.test(email.trim().toLowerCase())
    if(isEmail) {
        setError('nombre', {type: 'valid', message: 'valid'})
        return false;
    }
    return true;
  };
  
  return (
    <form ref={formEnvioRef} className={'form-dir-envio ' + className} onSubmit={handleSubmit(onSaveData)}>
            <h4>{isDirFact ? t('carrito.envio.form.title-fact') : t('carrito.envio.form.title')}</h4>
                {isDirFact && currentPaisId && currentPaisId !== 1 &&
                  <> 
                      <Alert severity="info">{t('carrito.envio.form.info_cif_ue.info')}</Alert>
                      <label className='form-dir-envio__intracomunitario'><input type='checkbox' onChange={handleIntracomunitario} ref={checkboxRef} />{t('carrito.envio.form.cif_ue')}</label>
                      {showNifNoIntracomunitario && <Alert severity="warning">{t('carrito.envio.form.info_cif_ue.no-intra')}</Alert>}
                  </>
                }
            <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.nombre')} *
                    <input type='text' disabled={
                      isDisabledInput('nombre') &&
                      dirEnvio?.nombre?.length >= 4 && 
                      /^[A-Za-z]/.test(dirEnvio?.nombre)
                    }
                        {...register('nombre', {
                          required: true,
                          minLength: 4,
                          pattern: {value: /^[A-Za-z]/}
                        })} />
                </label>
                {errors.nombre && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {
                  errors.nombre.message === 'valid' 
                  ? t('carrito.envio.form.errors.nombre.valid')
                  : t('carrito.envio.form.errors.nombre.required')
                }</span>}
            </div>
            {isDirFact && <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.cif')} *
                    <input type='text' disabled={
                        isDirFact && 
                        dirEnvio?.cif?.length >= 9 &&
                        /^[0-9a-zA-Z]+$/.test(dirEnvio?.cif)
                      }
                        {...register('cif', {
                          required: true,
                          pattern: { value: /^[0-9a-zA-Z]+$/},
                          minLength: 9,
                        })}
                        onBlur={(e) => {
                          if(currentPaisId && currentPaisId !== 1) handleIntracomunitario(e)
                          checkIsValidCif(e.target.value)
                        }} />
                </label>
                {errors.cif && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t(`carrito.envio.form.errors.cif`)}</span>}
            </div>}
            <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.telefono')} *
                    <input type='tel' disabled={
                        isDisabledInput('telefono') &&
                        dirEnvio?.telefono?.length >= 9 && 
                        /^\S.*[0-9]+$/.test(dirEnvio?.telefono)}
                        {...register('telefono', {
                          required: true,
                          pattern: {value: /^\S.*[0-9]+$/},
                          minLength: 9
                        })} />
                </label>
                {errors.telefono && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.telefono')}</span>}
            </div>
            {isDirFact && <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.email')} *
                    <input type='email' disabled={
                        isDirFact &&
                        dirEnvio?.email &&
                        /\S+@\S+\.\S+/.test(dirEnvio?.email)}
                        {...register('email', {
                          required: 'requerido',
                                  pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'formato'
                                  }
                        })} />
                </label>
                {errors.email && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t(`carrito.envio.form.errors.email.${errors.email.message}`)}</span>}
            </div>}
            
            { !(isDisabledInput('pais_id')) ?
              <div className='alert alert-info my-2'>
                <div className='d-flex align-items-center'>
                  <span><FontAwesomeIcon icon={faInfoCircle} color='#004085' /></span>
                  <div className='ms-3'>
                    <p>
                      {t('carrito.envio.form.extra_info.info')}
                      <b>{t('carrito.envio.form.extra_info.info_bold')}</b>
                      {t('carrito.envio.form.extra_info.info2')}
                    </p>
                    <p className='mt-2'>
                      {t('carrito.envio.form.extra_info.info3')}
                    </p>
                    <ul>
                      <li>{t('carrito.envio.form.extra_info.info_option1')}</li>
                      <li>{t('carrito.envio.form.extra_info.info_option2')}</li>
                    </ul>
                  </div>
                </div>
              </div>
              : null
            }
            
            <div className="form-dir-envio-extra">
              <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                  {t('carrito.envio.form.pais')} *
                  <InputPaises 
                    value={currentPaisId}
                    onChange={handleChangePais}
                    paises={checkoutInfo.presupuesto.ecommerce 
                        ? checkoutInfo.paises.filter(pais => pais.id === 1 || pais.id === 10) 
                        : checkoutInfo.paises}
                    isDisabled={isDisabledInput('pais_id') && isDisabledInput('direccion')}/>
                </label>
                {errors.pais_id && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.pais')}</span>}
              </div>
              
              {!isDisabledInput('direccion') &&<label className='form-dir-envio__label'>
                {t('carrito.envio.form.buscar')}
                <GooglePlacesAutocomplete 
                    country={watch('pais_id') 
                        ? (checkoutInfo.paises?.find(p => p.id === currentPaisId) 
                            ? checkoutInfo.paises?.find(p => p.id === currentPaisId)?.abbr 
                            : 'ES') 
                        : 'ES'}
                    onSelectResult={handleChangeDireccion}
                    disabled={isDisabledInput('direccion')}
                />
              </label>}
            </div>

            { !(isDisabledInput('pais_id')) ?
              <div className='alert alert-warning my-2'>
                <div className='d-flex align-items-center'>
                  <span><FontAwesomeIcon icon={faExclamationTriangle} color='#856404' /></span>
                  <p className='ms-3'>
                    {t('carrito.envio.form.extra_info.warning')}
                    <b>{t('carrito.envio.form.extra_info.warning_bold')}</b>
                    {t('carrito.envio.form.extra_info.warning2')}
                  </p>
                </div>
              </div>
              : null
            }

            <div className="form-dir-envio-extra">
              <div className='form-dir-envio__div form-dir-envio__cp'>
                  <label className='form-dir-envio__label'>
                      {t('carrito.envio.form.codigo_postal')} *
                      <input type='text' disabled={
                          isDisabledInput('codigo_postal') &&
                          dirEnvio?.codigo_postal?.length >= 4
                        }
                          {...register('codigo_postal', {
                            required: true,
                            minLength: 4,
                          })} />
                  </label>
                  {errors.codigo_postal && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.codigo_postal')}</span>}
              </div>
              
              <div className='form-dir-envio__div form-dir-envio__direccion'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.direccion')} *
                    <input type='text' disabled={
                        isDisabledInput('direccion') &&
                        dirEnvio?.direccion?.length >= 5 && 
                        /^[A-Za-z]/.test(dirEnvio?.direccion)
                      }
                        {...register('direccion', {
                          required: true,
                          minLength: 5,
                          pattern: {value: /^[A-Za-z]/}
                        })} />
                </label>
                {errors.direccion && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.direccion')}</span>}
              </div>
            </div>

            <div className="form-dir-envio-extra">
              <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.poblacion')} *
                    <input type='text' disabled={
                      isDisabledInput('poblacion') &&
                      dirEnvio?.poblacion?.length >= 3 && 
                      /^[A-Za-z]/.test(dirEnvio?.poblacion)
                    }
                        {...register('poblacion', {
                          required: true,
                          minLength: 3,
                          pattern: {value: /^[A-Za-z]/}
                        })} />
                </label>
                {errors.poblacion && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.poblacion')}</span>}
              </div>

              <div className='form-dir-envio__div'>
                <label className='form-dir-envio__label'>
                    {t('carrito.envio.form.area')} *
                    <input type='text' disabled={
                        isDisabledInput('area') &&
                        dirEnvio?.area?.length >= 5 && 
                        /^[A-Za-z]/.test(dirEnvio?.area)
                      }
                        {...register('area', {
                          required: true,
                          minLength: 5,
                          pattern: {value: /^[A-Za-z]/}
                        })} />
                </label>
                {errors.area && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.area')}</span>}
              </div>
            </div>

            {
              !isDirFact && <DeliverySchedule direccion={dirEnvio} setValue={setValue} />
            }
            <div className='form-dir-envio__button'>
                <button disabled={isLoading} className='form-dir-envio__button--btn' type='submit'>{isLoading ? <Spinner/> : <>{t('carrito.envio.form.guardar')}</>}</button>
            </div>
        </form>
  )
}

FormDirEnvio.propTypes = {
  className: PropTypes.string,
  dirEnvio: PropTypes.any,
  addNewDirection: PropTypes.func,
  uploadDirection: PropTypes.func,
  isDirFact: PropTypes.bool,
  formEnvioRef: PropTypes.any,
  isOpenDirFacturacion: PropTypes.bool,
  handleSelectDirFact: PropTypes.func
}
export default FormDirEnvio
